// unplugin 只能处理组件内引入
import { message, Modal } from 'ant-design-vue'
import 'ant-design-vue/es/message/style'
import 'ant-design-vue/es/modal/style'

import VueLoading, { PluginApi } from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import 'viewerjs/dist/viewer.css'
import VueViewer, { api as viewerApi } from 'v-viewer'

import { createCustomModal, createSysModal } from '@sys/plugins/modal'
import PopErrorPage from '@/components/PopErrorPage.vue'
import axiosInstance from './axios'

export interface GlobalProperties {
  $message: typeof message
  $axios: typeof axiosInstance
  $error: typeof Modal.error
  $confirm: typeof Modal.confirm
  $info: typeof Modal.info
  $popErrorPage: (modalProps: { message: string }) => Function
  $modal: (modalProps: {
    title?: string
    content: () => (JSX.Element | string) | JSX.Element | string
    onOk?: () => Promise<any> | void
    onCancel?: () => void
    visible?: Boolean
    fullscreen?: Boolean
  }) => Function
  $loading: PluginApi
  $viewerApi: typeof viewerApi
}
let _app
const setupGlobalProperties = (app) => {
  app.config.globalProperties.$message = message
  app.config.globalProperties.$axios = axiosInstance
  app.config.globalProperties.$error = Modal.error
  app.config.globalProperties.$confirm = Modal.confirm
  app.config.globalProperties.$info = Modal.info
  app.config.globalProperties.$popErrorPage = createCustomModal(app, PopErrorPage)
  app.config.globalProperties.$modal = createSysModal(app)
  app.use(VueLoading, {
    color: '#27c07a',
  })
  app.use(VueViewer)
  _app = app
}

const getGlobalProperties = () => {
  return _app?.config.globalProperties as GlobalProperties
}
export { setupGlobalProperties, getGlobalProperties }
