import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AntdIcon = _resolveComponent("AntdIcon");

  return $options.currentIcon ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.currentIcon), {
    key: 0
  })) : (_openBlock(), _createBlock(_component_AntdIcon, {
    key: 1,
    type: $props.type
  }, null, 8, ["type"]));
}