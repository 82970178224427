import { mapActions } from 'vuex';
import { isBlankLayout } from '@/service/navigate';
export default {
  props: {
    message: {
      type: String,
      default: '500 Server Error'
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isBlankLayout
  },
  methods: { ...mapActions(['Logout']),

    refresh() {
      window.location.reload();
    },

    logout() {
      this.Logout({
        type: 'reLaunch'
      }).catch(err => {
        this.$message.error({
          title: '错误',
          description: err.message
        });
      });
    }

  }
};