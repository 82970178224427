import treeUtils from '@sys/utils/treeUtils'
import store from '@/store'
import router from '@/router'
export default {
  mounted: function (el, binding) {
    let actionCode = []
    if (typeof binding.value === 'string') {
      actionCode = [binding.value]
    } else if (binding.value instanceof Array) {
      actionCode = [...binding.value]
    }
    const route = router.currentRoute.value
    // 有actions 字段的才是menu
    let actions = route.meta.actions
    let cache = route
    while (!actions) {
      cache = treeUtils.findParent(store.state.permission.menus, (i) => {
        return i.path === cache.path.replace(/^\/b\/(.*)/, '/$1')
      })
      if (cache) {
        // actions有值则终止循环
        actions = cache.meta.actions
      } else {
        // 终止循环
        actions = []
      }
    }
    let matched = false
    for (let i = 0; i < actions.length; i++) {
      // 只会替换匹配的文本内容，如果不是简单的文本内容。
      // 如：<div><a>新</a><a>增</a></div> 则不会替换。
      if (actionCode.includes(actions[i].code)) {
        // const text = el.textContent
        // el.innerHTML = el.innerHTML.replace(text, actions[i].name)
        matched = true
      }
    }
    if (!matched) {
      ;(el.parentNode && el.parentNode.removeChild(el)) || (el.style.display = 'none')
    }
  },
}
