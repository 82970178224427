import RenderJsx from '@sys/components/RenderJsx.vue'
import PictureCardUpload from '@sys/components/pictureCardUpload/PictureCardUpload.vue'
import AutoImg from '@sys/components/AutoImg.vue'
import BaseModal from '@sys/components/BaseModal.vue'
import AppIcon from './appIcon/AppIcon.vue'

const setupGlobalComponents = (app) => {
  app.component('RenderJsx', RenderJsx)
  app.component('AppIcon', AppIcon)
  app.component('AutoImg', AutoImg)
  app.component('BaseModal', BaseModal)
  app.component('PictureCardUpload', PictureCardUpload)
}
export { setupGlobalComponents }
