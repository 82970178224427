import type { ExtendsRouter } from '@sys/types'
import { createRouter, createWebHistory } from 'vue-router'
import { constantRoutes } from './routes'
const createAppRouter = () => {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: constantRoutes,
  })
  return router
}

const router = createAppRouter()
export const resetAsyncRoutes = (routes) => {
  routes.forEach((route) => {
    router.addRoute(route)
  })
}
export default router as ExtendsRouter
