export default {
  data() {
    return {
      loading: true,
      error: false
    };
  },

  props: {
    src: {
      type: String,
      default: ''
    },
    fit: {
      type: String,
      default: ''
    }
  },
  methods: {
    onLoad() {
      this.loading = false;
      this.$emit('load');
    },

    onError() {
      this.loading = false;
      this.error = true;
      this.$emit('error');
    }

  },
  watch: {
    src: {
      handler(value) {
        if (value) {
          this.loading = true;
          this.error = false;
        } else {
          this.loading = false;
          this.error = true;
        }
      },

      immediate: true
    }
  },

  created() {},

  mounted() {}

};