// import '@/mock'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import ActionDirective from './directives/action'
const setupGlobalModules = (app) => {
  dayjs.locale('zh-cn')
  app.directive('action', ActionDirective)
}
export { setupGlobalModules }
