import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.error.cause.js";
import { ossUpload } from '../../service/oss';
import AutoImg from '../AutoImg.vue';
import dayjs from 'dayjs';
export default {
  data() {
    return {
      loading: false,
      outerStyle: {},
      observer: null,
      sourcePath: ''
    };
  },

  computed: {
    style() {
      const {
        itemHeight,
        itemWidth,
        outerStyle
      } = this;
      const {
        width = '148px',
        height = '148px'
      } = outerStyle;
      const style = {
        height: itemHeight ? parseFloat(itemHeight) + 'px' : height,
        width: itemWidth ? parseFloat(itemWidth) + 'px' : width
      };
      return style;
    },

    minSide() {
      const w = parseFloat(this.style.width);
      const h = parseFloat(this.style.height);
      return w < h ? w : h;
    }

  },
  watch: {
    value: {
      immediate: true,

      handler(v) {
        this.sourcePath = v;
      }

    }
  },
  props: {
    // value 不可设置默认值, 否则v-decrator警告: xxx default value can not collect, please use option.initialValue to set default value.`
    // eslint-disable-next-line
    value: {
      type: String
    },
    storeAs: {
      type: Function,

      default(file, key = '') {
        const suffix = file.name.match(/\.[^ .]+$/)[0];
        return `${key}/${dayjs().format('YYYY/MM')}/${new Date().getTime()}${suffix}`;
      }

    },
    fit: {
      type: String,
      default: ''
    },
    preview: {
      type: [Boolean, Object],
      default: false
    },
    itemWidth: {
      type: [String, Number],
      default: ''
    },
    itemHeight: {
      type: [String, Number],
      default: ''
    },
    // 启用beforeUpload以后失效
    // 只限制上传，不限制展示
    fileType: {
      type: String,
      default: 'image'
    },
    // 启用beforeUpload以后失效
    maxFileSize: {
      type: Number,
      default: 2
    },
    beforeUpload: {
      type: Function || null,
      default: null
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.outerStyle = this.getOuterStyle();
    this.observer = new MutationObserver(() => {
      this.outerStyle = this.getOuterStyle();
    });
    this.observer.observe(this.$el, {
      attributes: true
    });
  },

  destroyed() {
    this.observer.disconnect();
  },

  methods: {
    getOuterStyle() {
      let i = 0;
      const style = {};

      while (this.$el?.style[i]) {
        const outerStyleItemName = this.$el.style[i];
        style[outerStyleItemName] = this.$el.style[outerStyleItemName];
        i++;
      }

      return style;
    },

    getFileName(src) {
      return src.replace(/(.*\/)*([^.]+)/gi, '$2').replace(/\?.+/, '');
    },

    // 推测的文件类型
    getFileType(sourcePath) {
      let fileType = ''; // 后缀获取

      let suffix = '';

      try {
        const flieArr = new URL(sourcePath).pathname.split('.');
        suffix = flieArr[flieArr.length - 1].toLowerCase();
      } catch (err) {
        suffix = '';
      }

      const imageList = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];

      if (imageList.includes(suffix)) {
        fileType = 'image';
      }

      const videoList = ['mp4', 'm2v', 'mkv'];

      if (videoList.includes(suffix)) {
        fileType = 'video';
      }

      const wordList = ['docx', 'doc'];

      if (wordList.includes(suffix)) {
        fileType = 'word';
      }

      return fileType;
    },

    upload(file) {
      // 已在内部实现上传，需要禁用默认上传
      const doUpload = () => {
        const prevPath = this.sourcePath;
        this.sourcePath = '';
        this.loading = true;
        const storeAs = this.storeAs(file);
        ossUpload(file, storeAs).then(path => {
          this.$emit('update:value', path);
        }).catch(e => {
          if (this.$listeners.uploadFail) {
            this.$emit('uploadFail', e);
          } else {
            console.error(e);
            this.$message.error('上传失败');
          } // 失败以后还原上一次成功的path


          this.sourcePath = prevPath;
        }).finally(() => {
          this.loading = false;
        });
      };

      if (this.beforeUpload) {
        const res = this.beforeUpload(file);

        if (res === false) {
          return false;
        }

        if (res instanceof Promise) {
          return res.then(doUpload) // return Promise.reject 禁用默认上传
          .then(() => Promise.reject(new Error('STOP_ANT_DEFAULT_UPLOAD')));
        }
      } else {
        const {
          fileType,
          maxFileSize
        } = this;
        const [type] = file.type.split('/');

        if (fileType) {
          const allowType = fileType.split(',');

          if (!allowType.includes(type)) {
            this.$message.error(`请上传${allowType}类型文件`);
            return false;
          }
        }

        const isLtSize = file.size / 1024 / 1024 < maxFileSize;

        if (!isLtSize) {
          this.$message.error(`上传文件不能大于${maxFileSize}M!`);
          return false;
        }
      }

      doUpload(); // return false 禁用默认上传

      return false;
    },

    previewTypeOther(sourcePath) {
      window.open(sourcePath);
    },

    deleteImg() {
      this.$emit('update:value', '');
    }

  }
};