import { debounce } from 'lodash-es'
export const setupSystemRouter = (app) => {
  const { $router, $store } = app.config.globalProperties
  let nextRouteChange = false
  let nextReload = false
  let cache = null
  let params = null
  const originPush = $router.push
  const debouncedPush = debounce(originPush, 300, { leading: true, trailing: false })
  $router.afterEach(() => {
    params = null
    if (nextRouteChange) {
      params = cache
    }
    nextRouteChange = false
    nextReload = false
  })
  $router.beforeEach((to, from, next) => {
    if (nextReload) {
      if (to.meta.keepAlive) {
        $store.commit('SET_NEXT_REFRESH_KEEP_ALIVE', to.fullPath)
      }
    }
    next()
  })
  // 传参到下个路由的activated
  $router.setJumpData = (params) => {
    nextRouteChange = true
    cache = params
  }
  $router.reloadNext = () => {
    nextReload = true
  }
  $router.reload = (path = $router.currentRoute.fullPath) => {
    $store.dispatch('ReloadRoute', path)
  }
  $router.removeKeepAlive = (path = $router.currentRoute.fullPath) => {
    $store.dispatch('RemoveKeepAlive', path)
  }
  $router.getJumpData = () => {
    return params
  }
  // 默认开启相对路径防抖
  $router.push = (params) => {
    let path = ''
    let isDebounce = params.debounce !== false
    if (typeof params === 'string') {
      path = params.split('?')[0]
    } else {
      path = params.path
    }
    // 对相对路径的跳转防抖（用户短时间多次点击触发跳转导致相对路径会跳转到错误的页面）
    if (isDebounce && !path.startsWith('/')) {
      return debouncedPush(params)
    }
    return originPush(params)
  }
  return $router
}
