import { nextTick } from 'vue'
export const setupSystemStore = (app) => {
  const { $store, $router } = app.config.globalProperties
  const system = {
    state: {
      loaded: true,
      keepAliveInclude: [],
      keepAliveRouteMap: {},
      nextRefreshKeepAlive: '', // routePath
    },
    mutations: {
      SET_NEXT_REFRESH_KEEP_ALIVE(state, path) {
        state.nextRefreshKeepAlive = path
      },
      UPDATE_KEEP_ALIVE_INCLUDE: (state, keepAliveInclude) => {
        state.keepAliveInclude = keepAliveInclude
      },
      SET_KEEP_ALIVE_ROUTE_MAP: (state, { path, name }) => {
        state.keepAliveRouteMap[path] = name
      },
      UPDATE_LOADED(state, loaded) {
        state.loaded = loaded
      },
    },
    actions: {
      async ReloadRoute({ state, commit, dispatch }, path) {
        // 当前路由才需要重载
        if (path === $router.currentRoute.fullPath) {
          commit('UPDATE_LOADED', false)
        }
        await nextTick()
        // 如果是已缓存的则更新
        if (state.keepAliveRouteMap[path]) {
          commit('SET_NEXT_REFRESH_KEEP_ALIVE', path)
          await dispatch('SetKeepAlive', path)
        }
        if (path === $router.currentRoute.fullPath) {
          commit('UPDATE_LOADED', true)
        }
      },
      async SetKeepAlive({ commit, state }, path) {
        const keepAliveInclude = [...state.keepAliveInclude]

        const index = keepAliveInclude.findIndex((v) => v === path)
        // 如果存在的话，判断当前需不需要刷新；不存在，则直接在后面添加
        if (index > -1) {
          // 如果需要刷新，先删除掉当前的，再在同一位置通过setTimeout让视图重新渲染，再把删除掉的重新添加进去。
          if (state.nextRefreshKeepAlive) {
            keepAliveInclude.splice(index, 1)
            commit('UPDATE_KEEP_ALIVE_INCLUDE', [...keepAliveInclude])
            await nextTick()
            keepAliveInclude.splice(index, 0, path)
            commit('UPDATE_KEEP_ALIVE_INCLUDE', [...keepAliveInclude])
          }
        } else {
          commit('UPDATE_KEEP_ALIVE_INCLUDE', [...keepAliveInclude, path])
        }
        // console.log(state)
        // 最后都需要重置刷新标识
        commit('SET_NEXT_REFRESH_KEEP_ALIVE', '')
      },
      async RemoveKeepAlive({ commit, state }, path) {
        const keepAliveInclude = [...state.keepAliveInclude]
        const index = keepAliveInclude.findIndex((key) => key === path)
        if (index > -1) {
          keepAliveInclude.splice(index, 1)
          commit('UPDATE_KEEP_ALIVE_INCLUDE', keepAliveInclude)
        }
      },
    },
  }
  $store.registerModule('system', system)
}
