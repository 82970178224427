import { createStore, createLogger } from 'vuex'

import user from './modules/user'

import createPersistedState from 'vuex-persistedstate'
import permission from './modules/permission'

import getters from './getters'

export default createStore({
  modules: {
    user,
    permission,
  },
  state: {},
  mutations: {},
  actions: {},
  getters,
  plugins: [
    createPersistedState({
      paths: ['user'],
      storage: localStorage,
    }),
    createLogger(),
  ],
})
