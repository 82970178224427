import { merge } from 'lodash-es'
let config = {}
const initConfig = function () {
  let cc = {}
  cc = merge({}, require('./base.config'), require('./env/prod.config'))
  console.log(process.env.NODE_ENV, process.env.VUE_APP_BUILD_ENV)
  if (process.env.VUE_APP_BUILD_ENV) {
    cc = merge({}, cc, require(`./env/${process.env.VUE_APP_BUILD_ENV}.config`))
  } else {
    if (process.env.NODE_ENV === 'development') {
      cc = merge({}, cc, require('./env/dev.config'))
      try {
        // 动态去搜寻local.config.js,如果有local.config.js，就使用local.config.js ,否则使用 /env/dev.config
        const localConfigContext = require.context('./', false, /local\.config\.js$/)
        const requireAll = (context) => context.keys().map(context)
        const localConfig = requireAll(localConfigContext)[0]
        const c = localConfig
        cc = merge({}, cc, c)
      } catch (e) {}
    }
  }
  return cc
}
config = initConfig()
export default config
