import { permissionMenuRoutes, asyncRoutes, processedDefaultMenus } from '@/router/routes'
import treeUtils from '@sys/utils/treeUtils'

const pageWebview = () => import('@/pages/Webview')
const page404 = () => import('@/pages/exception/404')
/**
 *
 * @param {*} apiMenus 基于原始接口数据做处理
 * @returns 基于route的menuData
 */
function menuAdaptor(menus) {
  return treeUtils.map(menus, (item) => {
    return {
      // 菜单基础信息
      menuUrl: item.menuUrl || '',
      menuName: item.menuName || '',
      menuIcon: item.menuIcon || '',
      menuType: item.menuType,
      menuCode: item.authCode, //  唯一值
      menuOrder: item.orderIndex,
      menuTarget: '', // 'blank' || ''
      menuHidden: false,
      menuComponentPath: '',
      // 额外信息
    }
  })
}
/**
 *
 * @param {*} apiMenus 基于menuAdator数据的menu
 * @returns 基于route的menuData
 */
function getMenuData(apiMenus) {
  const menuData = treeUtils.map(apiMenus, (item) => {
    const uniq = item.menuCode
    // path必须是标准的vue路由path且唯一,url为原始配置地址。
    let path = encodeURI(`/m/__${uniq}__`)
    let url = item.menuUrl
    // 如果填写的是标准路径，直接使用，且不再解析原始配置地址
    if (item.menuUrl.startsWith('/')) {
      path = item.menuUrl
      url = ''
    }

    const actions = []
    if (item.children) {
      // 最简单的菜单模型（只有 叶子 为 菜单），可以把在adaptor把menuType先默认设置为C，如果有children 为 M
      // 指定哪些菜单为目录（需要重定向的）
      // if (item.children.length) {
      //   item.menuType = 'M'
      // }
      item.children = item.children.filter((child) => {
        if (child.menuType === 'A') {
          actions.push({
            name: child.menuName,
            code: child.menuCode,
          })
          return false
        }
        return true
      })
    }
    const res = {
      path: path,
      meta: {
        title: item.menuName,
        icon: item.menuIcon,
        type: item.menuType,
        code: item.menuCode,
        url: url,
        target: item.menuTarget,
        actions,
        hidden: item.menuHidden,
        order: item.menuOrder,
      },
    }
    if (item.menuComponentPath) {
      res.component = () => {
        return new Promise((resolve) => {
          import(`@/pages/${item.menuComponentPath}`)
            .then((comp) => {
              resolve(comp)
            })
            .catch(() => {
              resolve(page404())
            })
        })
      }
    }
    return res
  })
  return menuData
}

/**
 * 创建用户的menu,并用预设的前端menu补充信息
 * 本地路由集中有的，但远程菜单没有的，会自动添加hidden
 * @param {*} localRouteMap 本地路由集
 * @param {*} menu 用户菜单
 */
function processPermissionMenus(localRouteMap, menu) {
  const r = {}
  treeUtils.forEach(localRouteMap, (route) => {
    if (route.path) {
      r[route.path] = {
        ...route,
      }
    }
  })
  // 补全menu信息(会添加子路由，但是只循环menu)
  treeUtils.forEach(
    menu,
    (m) => {
      const localRoute = r[m.path]
      // 存在本地路由
      if (localRoute) {
        for (const j in m.meta) {
          if (!m.meta[j]) {
            m.meta[j] = localRoute.meta[j]
          }
        }
        if (!m.component) {
          m.component = localRoute.component
        }
        if (localRoute.children) {
          // 菜单中隐藏本地路由
          treeUtils.forEach(localRoute.children, (child) => {
            child.meta.hidden = true
          })
          m.children = localRoute.children
          m.skip = true
        }
        if (localRoute.redirect) {
          m.redirect = localRoute.redirect
        }
      }
    },
    {
      skip: (item) => {
        return item.skip
      },
    }
  )
  // 补全redirect和component(循环menu 和添加的 子路由)
  treeUtils.forEach(menu, (m) => {
    // 处理无法解析到路由的url
    if (m.meta.url) {
      // 如果是需要外部打开的，则重定向；否则使用webview打开
      if (m.meta.target === 'blank') {
        m.redirect = {
          path: '/redirect',
          query: {
            url: m.meta.url,
          },
        }
      } else {
        m.component = pageWebview
      }
    }
    // 如果是目录，自动添加重定向到下级
    if (m.meta.type === 'M' && !m.redirect) {
      const activeChild = m.children?.find((routeChild) => !routeChild.meta.hidden)
      if (activeChild) {
        m.redirect = activeChild.path
      }
    }

    if (!(m.component || m.redirect)) {
      m.component = page404
    }
  })
  return menu
}
// 通过角色分配默认菜单
function pickProcessedPermissionMenusByRoles(_roles) {
  return processedDefaultMenus
}
/**
 *
 * @param {*} menus 原始接口数据
 * @returns
 */
export function generateMenus(menus, roles) {
  // menuData 只包含menu数据
  const menuData = getMenuData(menuAdaptor(menus))
  // 基于menuData 创建完整的routes 包含 redirect / component / meta
  const processedPermissionMenus = processPermissionMenus(permissionMenuRoutes, menuData)
  const processedDefaultMenusByRoles = pickProcessedPermissionMenusByRoles(roles)
  return processedPermissionMenus.concat(processedDefaultMenusByRoles)
}

export function generateAccessRouters(menus, routerMap = asyncRoutes) {
  const accessRouters = []
  const blankAccessRouters = []
  const rootPermissionRouters = routerMap.find((v) => v.path === '/m')
  const rootBlankPermissionRoutes = routerMap.find((v) => v.path === '/b')

  treeUtils.forEach(menus, (route) => {
    const { children: _children, ...r } = route
    if (r.path) {
      accessRouters.push(r)
      const blankAccessRoute = {
        ...r,
      }
      if (r.path) {
        blankAccessRoute.path = `/b${r.path}`
      }
      if (r.redirect) {
        blankAccessRoute.redirect = `/b${r.redirect}`
      }
      blankAccessRouters.push(blankAccessRoute)
    }
  })

  rootPermissionRouters.children = accessRouters
  rootBlankPermissionRoutes.children = blankAccessRouters
  return routerMap
}
