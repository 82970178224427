import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios'
import { defaultsDeep, merge } from 'lodash-es'
import qs from 'qs'
import EventEmitter from 'events'
interface _AxiosRequestConfig<D = any> extends AxiosRequestConfig<D> {
  showError?: boolean // 展示错误的类型
  redirect?: boolean // 重定向的处理类型
  showLoading?: boolean //
  showProgress?: boolean
  postDataType?: 'json' | 'form' | 'formData'
  authType?: '' | 'Bearer'
  interceptors?: {
    // 为false 时，不再经过某个interceptor
    request?: boolean
    response?: boolean
    error?: boolean
  }
}
interface _AxiosInstance extends AxiosInstance {
  <T = any, R = AxiosResponse<T>, D = any>(config: _AxiosRequestConfig<D>): Promise<R>
  <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: _AxiosRequestConfig<D>): Promise<R>
  request<T = any, R = AxiosResponse<T>, D = any>(config: _AxiosRequestConfig<D>): Promise<R>
  get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: _AxiosRequestConfig<D>): Promise<R>
  delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: _AxiosRequestConfig<D>): Promise<R>
  head<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: _AxiosRequestConfig<D>): Promise<R>
  options<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: _AxiosRequestConfig<D>): Promise<R>
  post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: _AxiosRequestConfig<D>): Promise<R>
  put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: _AxiosRequestConfig<D>): Promise<R>
  patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: _AxiosRequestConfig<D>): Promise<R>
  postForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: _AxiosRequestConfig<D>): Promise<R>
  putForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: _AxiosRequestConfig<D>): Promise<R>
  patchForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: _AxiosRequestConfig<D>): Promise<R>
  dangerouslyCancelAllAxios?: () => void
}
interface ErrorCodeMap {
  E_REQUEST_CANCEL: string
  E_HAS_RESOLVED: string
  E_UNEXCEPTED_RESPONESE: string
  E_SERVER_ERROR: string
  E_NETWORK_ERROR: string
  E_TIME_OUT: string
  E_NO_DEF: string
}
const createAxiosInstance: (configFns: any, errorCodeMap: ErrorCodeMap) => _AxiosInstance = (
  {
    getConfig,
    getAuth,
    getBaseUrl,
    showError,
    showLoading,
    showProgress,
    hideLoading,
    hideProgress,
    validResponse,
    is401,
    getErrorMessage,
    extraErrorHandle,
    extraResponseHandle,
    extraRequestHandle,
    doRefreshToken,
    doLogout,
  } = {},
  errorCodeMap = {
    E_REQUEST_CANCEL: '请求已取消',
    E_HAS_RESOLVED: '忽略处理',
    E_UNEXCEPTED_RESPONESE: '数据异常',
    E_SERVER_ERROR: '服务小哥开小差了，请稍后重试！',
    E_NETWORK_ERROR: '网络异常',
    E_TIME_OUT: '请求超时',
    E_NO_DEF: '未定义的请求错误类型',
  }
) => {
  const ee = new EventEmitter()
  const source = axios.CancelToken.source()
  const instance: _AxiosInstance = axios.create({ cancelToken: source.token })
  instance.dangerouslyCancelAllAxios = function () {
    source.cancel('GLOBAL_CANCEL')
  }
  const enhanceAxiosError = function (error) {
    error.originMessage = error.message
    if (error instanceof axios.Cancel) {
      ;(error as any).code = 'E_REQUEST_CANCEL'
      error.message = errorCodeMap['E_REQUEST_CANCEL']
    } else {
      if (error.response) {
        // 理论上只有2种类型的错误，数据格式不匹配，和没通过validate校验
        if (error.originMessage === 'Unexpected Response') {
          error.code = 'E_UNEXCEPTED_RESPONESE'
          error.message = getErrorMessage(error.response) || errorCodeMap['E_UNEXCEPTED_RESPONESE']
          error.data = error.response.data
        } else {
          // 其他服务器返回错误
          error.code = 'E_SERVER_ERROR'
          error.status = error.response.status
          error.message = getErrorMessage(error.response) || errorCodeMap['E_SERVER_ERROR']
          error.data = error.response.data
          // 理论上接口的status 只会返回200 和 500
          // switch (error.status) {
          // case 401:
          //   error.message = "登录凭证过期";
          //   break;
          // case 403:
          //   error.message = "禁止访问，请联系管理员！";
          //   break;
          // }
        }
      } else {
        if (error.originMessage === 'Network Error') {
          error.code = 'E_NETWORK_ERROR'
          error.message = errorCodeMap['E_NETWORK_ERROR']
        } else if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') > -1) {
          error.code = 'E_TIME_OUT'
          error.message = errorCodeMap['E_TIME_OUT']
        } else {
          error.code = 'E_NO_DEF'
          error.message = errorCodeMap['E_NO_DEF']
        }
      }
    }
    return error
  }
  const tryRefreshToken = (function () {
    let refreshTokenLock = false
    return () => {
      return new Promise((resolve, reject) => {
        if (!refreshTokenLock) {
          // 一次session 内只会做一次refresh 操作
          refreshTokenLock = true
          return doRefreshToken()
            .then(() => {
              ee.emit('refreshToken', true)
              resolve(void 0)
            })
            .catch((e) => {
              console.warn(`[refresh token fail] ${e.message}`)
              reject(e)
            })
        } else {
          ee.once('refreshToken', function () {
            return resolve(void 0)
          })
        }
      })
    }
  })()
  function authFail(e) {
    // 默认的重定向操作，需要节流
    doLogout(e)
    // 每个请求都需要及时返回错误
    return Promise.reject(e)
  }
  async function axiosErrorHandle(error) {
    const axiosError = extraErrorHandle(enhanceAxiosError(error))
    if (axiosError.config && axiosError.config.interceptors.error) {
      if (is401(axiosError)) {
        const { accessToken, refreshToken } = getAuth()
        if (accessToken && refreshToken) {
          try {
            await tryRefreshToken()
            const result = await instance.request(axiosError.config)
            return result
          } catch (e: any) {
            // 特殊处理码
            e.code = 'E_HAS_RESOLVED'
            e.exceptionCode = 'E_REFRESH_FAIL'
            return authFail(e)
          }
        } else {
          // 特殊处理码
          axiosError.code = 'E_HAS_RESOLVED'
          axiosError.exceptionCode = 'E_NO_REFRESHTOKEN'
          return authFail(axiosError)
        }
      }

      if (axiosError.config.showError) {
        showError(axiosError)
      }
    }
    return Promise.reject(axiosError)
  }
  instance.interceptors.request.use(
    (_req) => {
      const req = defaultsDeep(
        _req,
        merge(
          {
            showError: true, // 展示错误的类型
            redirect: true, // 重定向的处理类型
            showLoading: false, //
            showProgress: true,
            postDataType: 'json',
            authType: '',
            interceptors: {
              // 为false 时，不再经过某个interceptor
              request: true,
              response: true,
              error: true,
            },
          },
          getConfig()
        )
      )
      if (req.interceptors.request) {
        if (req.showProgress) {
          //  will show progress
          showProgress()
        }
        if (req.showLoading) {
          //  will show loading
          showLoading()
        }
        if (req.interceptors.request) {
          if (req.method === 'post') {
            if (req.postDataType === 'formData') {
              req.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8'
              req.transformRequest = (data) => {
                const formData = new FormData()
                for (const i in data) {
                  formData.append(i, data[i])
                }
                return formData
              }
            }
            if (req.postDataType === 'form') {
              req.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
              req.transformRequest = (data) => {
                return qs.stringify(data)
              }
            }
            if (req.postDataType === 'json') {
              req.headers['Content-Type'] = 'application/json;charset=UTF-8'
              req.transformRequest = (data) => {
                // 避免空请求体，默认传一个 {}
                return JSON.stringify(data || {})
              }
            }
          }

          if (req.authType === 'Bearer') {
            const { accessToken } = getAuth()
            if (accessToken) {
              req.headers['Authorization'] = `Bearer ${accessToken}`
            }
          }
          req.baseURL = getBaseUrl(req)
        }
      }
      return extraRequestHandle(req)
    },
    (error) => {
      return axiosErrorHandle(error)
    }
  )
  instance.interceptors.response.use(
    (_res) => {
      const res = extraResponseHandle(_res)
      // 优先处理进度条和loading的显示
      if (res.config.showProgress) {
        // will hide progress
        hideProgress(true)
      }
      if (res.config.showLoading) {
        // will hide loading
        hideLoading()
      }
      if (res.config.interceptors.response) {
        if (validResponse(res)) {
          return Promise.resolve(res.data)
        } else {
          const error = new Error('Unexpected Response')
          Object.assign(error, {
            response: res,
            request: res.request,
            config: res.config,
          })
          return axiosErrorHandle(error)
        }
      } else {
        return Promise.resolve(res)
      }
    },
    (error) => {
      // 优先处理进度条和loading的显示

      if (error instanceof axios.Cancel || error.config?.showProgress) {
        // will hide progress
        hideProgress(false)
      }
      if (error instanceof axios.Cancel || error.config?.showLoading) {
        // will hide loading
        hideLoading()
      }
      return axiosErrorHandle(error)
    }
  )

  return instance
}
export default createAxiosInstance
