import { createApp } from 'vue'
// import 'virtual:windi-base.css'
import 'windi-components.css'
import 'windi-utilities.css'

import App from './App.vue'
import store from '@/store'
import router from '@/router'

import { setupGlobalModules } from '@/core'
import { setupGlobalComponents } from '@/components'
import { setupGlobalProperties } from '@/plugins'
import { setupRouterGuard } from '@/router/guard'
import { setupSystem } from '@sys/index'
const app = createApp(App)

app.use(store)
app.use(router)

setupGlobalModules(app)

setupGlobalComponents(app)

setupGlobalProperties(app)

setupRouterGuard(app)

setupSystem(app)

app.mount('#app')
