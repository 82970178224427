import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  return $options.loaded ? (_openBlock(), _createBlock(_component_router_view, {
    key: 0
  }, {
    default: _withCtx(({
      Component,
      route
    }) => [$data.enabelTransition ? (_openBlock(), _createBlock(_Transition, {
      key: 0,
      name: "page-transition",
      mode: "out-in"
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, {
        include: $options.keepAliveInclude
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent($options.keepAliveComponent(Component, route)), {
        key: route.path
      }))], 1032, ["include"]))]),
      _: 2
    }, 1024)) : (_openBlock(), _createBlock(_KeepAlive, {
      key: 1,
      include: $options.keepAliveInclude
    }, [(_openBlock(), _createBlock(_resolveDynamicComponent($options.keepAliveComponent(Component, route)), {
      key: route.path
    }))], 1032, ["include"]))]),
    _: 1
  })) : _createCommentVNode("", true);
}