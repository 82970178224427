import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      visible: false,
      modal: {
        visible: false
      }
    };
  },

  computed: { ...mapGetters(['nickname']),

    uInfo() {
      const arr = [this.$store.state.user.nickname].filter(v => !!v);
      return arr.join(' ');
    }

  },
  methods: { ...mapActions(['Logout']),

    onClickAvatar() {
      this.visible = !this.visible;
    },

    handleLogout() {
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk: () => {
          this.$store.commit('RESET_PERMISSION');
          return this.Logout({
            type: 'reLaunch'
          }).catch(err => {
            this.$message.error({
              title: '错误',
              description: err.message
            });
          });
        },

        onCancel() {}

      });
    }

  }
};