import md5 from 'crypto-js/md5'
import config from '@/config'
import { isArray, isObject, cloneDeep } from 'lodash-es'
function doSign(signStr) {
  return md5(signStr).toString()
}
// 对传入的data转换成排序过后的json对象
// 会对数组排序，并忽略对象中为undefined和null的值
function getSorted(data) {
  if (isArray(data)) {
    const out = []
    data.map((item) => {
      const sorted = getSorted(item)
      out.push(sorted)
    })
    out.sort()
    return '[' + out.join(',') + ']'
  } else if (isObject(data)) {
    const keys = Object.keys(data).sort()
    const out = []
    keys.map((key) => {
      const value = data[key]
      // undefined 或 null 跳过
      if (!(value === undefined || value === null)) {
        out.push(JSON.stringify(key) + ':' + getSorted(value))
      }
    })
    return '{' + out.join(',') + '}'
  } else {
    return JSON.stringify(data)
  }
}
export function getSignHeaders(data) {
  const { appId, secretKey } = config.signConfig
  const timestamp = new Date().getTime()
  const source = 'web'
  // data为null 或 undefined时，直接视作用{}转换
  const sortedData = getSorted(data ? cloneDeep(data) : {})
  const signStr = `${appId}|${timestamp}|${source}|${sortedData}${secretKey}`
  // console.log(signStr)
  return {
    appId,
    timestamp,
    source,
    signature: doSign(signStr),
  }
}
