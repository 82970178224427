const defaults = {
  children: 'children',
}

// 返回的是树的一个节点
// 类似数组的find ，只会搜索匹配的第一个。
const find = (data, predicate, options = {}) => {
  const config = Object.assign({}, defaults, options)

  let result = data.find((c) => predicate(c))
  if (result) {
    return result
  }
  for (let i = 0; i < data.length; i++) {
    const item = data[i]

    if (item[config.children] && item[config.children].length > 0) {
      result = find(item[config.children], predicate, config)
      if (result) {
        return result
      }
    }
  }
  return result
}
// 返回的是包含多个树节点 的数组
// 数组的每一项都是一个树节点
const findAll = (treeData, predicate, options = {}) => {
  const arr = treeToArray(treeData, options).filter((c) => predicate(c))
  return arr
}
// 返回的是树的一个节点
// 类似数组的find ，只会搜索匹配的第一个，只是这里返回的是匹配元素的父节点。
const findParent = (data, predicate, options = {}) => {
  const config = Object.assign({}, defaults, options)

  let parent
  for (let i = 0; i < data.length; i++) {
    if (parent) return parent

    const item = data[i]

    if (item[config.children] && item[config.children].length > 0) {
      const child = item[config.children].filter((c) => predicate(c))
      if (child.length > 0) {
        parent = item
      }

      if (parent === undefined) {
        parent = findParent(item[config.children], predicate, config)
      } else {
        break
      }
    }
  }
  return parent
}

// 由index构成，以短横线分割，如 0-0-0
const findIndexId = (treeData, predicate, options = {}) => {
  const config = Object.assign({}, defaults, options)

  let indexId = ''
  function wlk(o, loopId) {
    for (let i = 0; i < o.length; i++) {
      const originItem = o[i]
      const result = predicate(originItem)
      if (result) {
        indexId = loopId ? `${loopId}-${i}` : `${i}`
        break
      } else {
        if (originItem[config.children] && originItem[config.children].length) {
          const newLoopId = loopId ? `${loopId}-${i}` : `${i}`
          wlk(originItem[config.children], newLoopId)
        }
      }
    }
  }
  wlk(treeData, '')
  return indexId
}
const findByIndexId = (treeData, indexId, options = {}) => {
  const config = Object.assign({}, defaults, options)
  let result = treeData
  const indexIdArr = indexId.split('-')
  const length = indexIdArr.length
  let matchCount = 0
  while (indexIdArr.length) {
    const index = indexIdArr.shift()
    if (result && result[index]) {
      matchCount++
      if (matchCount === length) {
        // 无下层了，直接返回当前；
        return result[index]
      } else {
        result = result[index][config.children]
      }
    }
  }
}
const findAllParents = (treeData, predicate, options = {}) => {
  const config = Object.assign({}, defaults, options)
  const result = []
  const indexId = findIndexId(treeData, predicate, config)
  if (indexId) {
    const indexIdArr = indexId.split('-')
    for (let index = 1; index < indexIdArr.length; index++) {
      let _indexId = ''
      for (let i = 0; i < index; i++) {
        _indexId ? (_indexId += `-${indexIdArr[i]}`) : (_indexId += `${indexIdArr[i]}`)
      }
      const parent = findByIndexId(treeData, _indexId)
      result.push(parent)
    }
  }
  return result
}
const findAllChildren = (data, predicate, options = {}) => {
  const config = Object.assign({}, defaults, options)
  const result = []
  const node = find(data, predicate, config)

  if (node && node[config.children] && node[config.children].length) {
    function wlk(o) {
      for (let i = 0; i < o.length; i++) {
        const originItem = o[i]
        result.push(originItem)
        if (originItem[config.children] && originItem[config.children].length) {
          wlk(originItem[config.children])
        }
      }
    }
    wlk(node[config.children])
  }
  return result
}
// map 返回值是整颗树
// 类似数组的map,map 函数忽略返回值的 children
const map = (treeData, predicate, options = {}) => {
  const config = Object.assign({}, defaults, options)
  const resultTree = []
  function wlk(o, t, loopId) {
    o.forEach((el, i) => {
      const indexId = loopId ? `${loopId}-${i}` : `${i}`
      t[i] = {}
      const newItem = predicate(el, indexId)
      if (newItem) {
        for (const j in newItem) {
          if (j !== config.children) {
            t[i][j] = newItem[j]
          }
        }
      }
      if (el[config.children]) {
        t[i][config.children] = []
        wlk(el[config.children], t[i][config.children], indexId)
      }
    })
  }
  wlk(treeData, resultTree, '')
  return resultTree
}
// 简单遍历，开销最小
const forEach = (treeData, predicate, options = { skip: () => false }) => {
  const config = Object.assign({}, defaults, options)
  function wlk(t, loopId) {
    for (let i = 0; i < t.length; i++) {
      const indexId = loopId ? `${loopId}-${i}` : `${i}`
      predicate(t[i], indexId)
      if (t[i][config.children] && t[i][config.children].length && config.skip && !config.skip(t[i], indexId)) {
        wlk(t[i][config.children], indexId)
      }
    }
  }
  wlk(treeData, '')
}

const treeToArray = (tree, options = { id: 'id', pid: 'pid' }) => {
  const config = Object.assign({}, defaults, options)

  const arr = []
  function wlk(t, parentId = '', loopId = '') {
    t.map((c, i) => {
      const indexId = loopId ? `${loopId}-${i}` : `${i}`
      c[config.pid] = parentId
      if (!c[options.id]) {
        c[config.id] = indexId
      }
      arr.push(c)
      if (c[config.children]) {
        wlk(c[config.children], c[config.id] || indexId, indexId)
      }
    })
  }
  wlk(tree)
  return arr
}
const arrayToTree = (array, options = { id: 'id', pid: 'pid' }) => {
  const config = Object.assign({}, defaults, options)
  const hashMap = {}
  array.map((item) => {
    hashMap[item[config.id]] = item
  })
  const roots = []
  array.map((item) => {
    const parent = hashMap[item[config.pid]]
    if (parent) {
      if (!parent[config.children]) {
        parent[config.children] = []
      }
      if (!parent[config.children].some((c) => c[config.id] === item[config.id])) {
        parent[config.children].push(item)
      }
    } else {
      roots.push(item)
    }
  })
  return roots
}
// root false,leaf false 每一级都单独校验是否过滤，任意节点不满足都会直接舍去后续所有
// root false,leaf true 保留满足的节点开始的子树，一直校验到叶子节点（即使子节点不满足，但是子节点的子节点满足则保留）
// root true,leaf false 保留满足的包含根节点的树，不会校验到叶子节点（任意子节点不满足，则该子节点的所有子节点都不满足）
// root true,leaf true  保留根节点开始，一直校验到叶子节点（即使子节点不满足，但是子节点的子节点满足则保留）
const filter = (treeData, predicate, options = { root: false, leaf: false }) => {
  const config = Object.assign({}, defaults, options)
  const startTree = treeData
  let resultTree = []

  const arr = []
  function wlk(t, parentLoopId = '', parents = [], markResolved = false) {
    t.map((c, i) => {
      c.__pid = parentLoopId
      const indexId = parentLoopId ? `${parentLoopId}-${i}` : `${i}`
      c.__id = indexId
      const result = markResolved || predicate(c, indexId)
      if (result) {
        const { [config.children]: children, ...rest } = c
        if (children) {
          rest.children = []
        }
        arr.push(rest)
        // 添加所有父元素
        if (config.root) {
          parents.forEach((child) => {
            const { [config.children]: children, ...rest } = child
            if (children) {
              rest.children = []
            }
            arr.push(rest)
          })
        }
      }
      if (c[config.children]) {
        if (result) {
          wlk(c[config.children], indexId, [...parents, c], config.leaf)
        } else {
          if (config.root || config.leaf) {
            wlk(c[config.children], indexId, [...parents, c], false)
          }
        }
      }
    })
  }
  wlk(startTree)
  const uniqIdArr = arr.reduce((pre, cur) => {
    if (pre.some((item) => item.__id === cur.__id)) {
      return pre
    } else {
      return pre.concat(cur)
    }
  }, [])
  resultTree = arrayToTree(uniqIdArr, { id: '__id', pid: '__pid', children: config.children })
  forEach(resultTree, (el) => {
    delete el.__id
    delete el.__pid
  })
  return resultTree
}
// 返回数组
const changeChildrenKey = (
  treeData,
  options = {
    newChildren: 'newChildren',
  }
) => {
  const config = Object.assign({}, defaults, options)
  function wlk(t) {
    return t.map((item) => {
      if (item[config.children]) {
        item[config.newChildren] = wlk(item[config.children])
        delete item.childTreeDto
      }
      return item
    })
  }

  const result = wlk(treeData)
  return result
}
const sort = (treeData, compareFn, options = {}) => {
  const config = Object.assign({}, defaults, options)
  treeData.sort(compareFn)

  forEach(
    treeData,
    (item) => {
      if (item[config.children]) {
        item[config.children].sort(compareFn)
      }
    },
    { ...options }
  )
  return treeData
}
export default {
  findParent,
  findAllParents,
  findAllChildren,
  find,
  forEach,
  map,
  filter,
  findAll,
  treeToArray,
  arrayToTree,
  findIndexId,
  findByIndexId,
  changeChildrenKey,
  sort,
}
