import type { RouteRecordRaw } from 'vue-router'

import { BasicLayout, BlankLayout } from '@/layouts'
// 静态路由
export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    meta: {
      title: '登陆',
    },
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/Login.vue'),
  },
  {
    path: '/sso',
    component: () => import(/* webpackChunkName: "init" */ '@/pages/Sso.vue'),
  },
  {
    meta: {
      title: '404',
    },
    path: '/404',
    component: () => import(/* webpackChunkName: "exception" */ '@/pages/exception/404.vue'),
  },
  {
    meta: {
      title: '403',
    },
    path: '/403',
    component: () => import(/* webpackChunkName: "exception" */ '@/pages/exception/403.vue'),
  },
]
// 由 后台接口返回的menus(结合permissionMenuRoutes 或者 纯动态) + processedDefaultMenus 动态添加的路由
export const asyncRoutes = [
  {
    path: '/m',
    component: BasicLayout,
    // 动态添加
    children: [],
  },
  {
    path: '/b',
    component: BlankLayout,
    // 动态添加
    children: [],
  },
]
// 完整menu项
export const processedDefaultMenus = []
// 普通route，需要进行二次处理才能成为完整menu项
export const permissionMenuRoutes = [
  {
    path: '/m/kpi/summary',
    meta: {
      title: '综合绩效',
    },
    component: () => import('@/pages/scopes/kpi/summary/Summary.vue'),
    children: [
      {
        path: '/m/kpi/summary/detail',
        meta: {
          title: '绩效详情',
          hidden: true,
        },
        component: () => import('@/pages/scopes/kpi/summary/Detail.vue'),
      },
    ],
  },
  {
    path: '/m/kpi/output',
    meta: {
      title: '元均产值',
    },
    component: () => import('@/pages/scopes/kpi/Output.vue'),
  },
  {
    path: '/m/kpi/bsc',
    meta: {
      title: '平衡计分卡',
    },
    component: () => import('@/pages/scopes/kpi/Bsc.vue'),
  },
  {
    path: '/m/kpi/rewardNPunish',
    meta: {
      title: '单项奖罚',
    },
    component: () => import('@/pages/scopes/kpi/RewardNPunish.vue'),
  },
]
