import nprogress from 'nprogress'
import { constantRoutes } from '@/router/routes'
import treeUtils from '@sys/utils/treeUtils'
import { is401, doLogout } from '@/plugins/axios/configFns'
const whiteList = []
treeUtils.forEach(constantRoutes, (item) => {
  const whiteMenu = item.redirect || item.path
  if (!whiteList.includes(whiteMenu)) {
    whiteList.push(whiteMenu)
  }
})
export function setupRouterGuard(app) {
  const { $router } = app.config.globalProperties
  createTitleGuard($router)
  createProgressGuard($router)
  createPermissionGuard(app)
}
const setDocumentTitle = function (title) {
  document.title = `${title} - ${process.env.VUE_APP_SITE_TITLE}`
  const ua = navigator.userAgent
  // eslint-disable-next-line
  const regex = /\bMicroMessenger\/([\d\.]+)/
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe')
    i.src = '/favicon.ico'
    i.style.display = 'none'
    i.onload = function () {
      setTimeout(function () {
        i.remove()
      }, 9)
    }
    document.body.appendChild(i)
  }
}
function createTitleGuard(router) {
  router.beforeEach(async (to) => {
    to.meta?.title && setDocumentTitle(to.meta.title)
  })
}
function createProgressGuard(router) {
  router.beforeEach(async () => {
    nprogress.start()
  })

  router.afterEach(async () => {
    nprogress.done()
  })
}
function createPermissionGuard(app) {
  const { $popErrorPage, $router, $store } = app.config.globalProperties
  $router.beforeEach(async (to) => {
    if (!whiteList.includes(to.path)) {
      if ($store.state.user.auth.accessToken) {
        if (!$store.state.permission.loaded) {
          try {
            await $store.dispatch('SetRoute')
            return { ...to, replace: true }
          } catch (e) {
            if (is401(e)) {
              doLogout(e)
            } else {
              $popErrorPage({ message: e.message })
            }
          }
        }
      } else {
        return {
          path: '/login',
          query: { redirect: to.fullPath },
          // replace: true,
        }
      }
    }
  })
}
