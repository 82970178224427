import KeepAliveRouterView from '@sys/components/KeepAliveRouterView';
export default {
  name: 'BlankLayout',
  components: {
    KeepAliveRouterView
  },

  data() {
    return {};
  }

};