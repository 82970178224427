import { generateMenus, generateAccessRouters } from './menu'
const permission = {
  state: {
    loaded: false,
    menus: [],
    modules: [], // modules 暂时没用
  },
  mutations: {
    SET_ROUTE_LOADED: (state, loaded = true) => {
      state.loaded = loaded
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus
    },
    RESET_PERMISSION(state) {
      state.loaded = false
      state.menus = []
      state.modules = []
    },
  },
  actions: {
    async GenerateRoutes({}, { processedMenus }) {
      // 基于菜单创建真正的路由
      const accessRouters = generateAccessRouters(processedMenus)

      const asyncRouterMap = [
        ...accessRouters,
        // 一个菜单都没有，重定向到403
        {
          path: '/',
          redirect: processedMenus[0] ? processedMenus[0].path : '/403',
        },
        {
          path: '/:path(.*)*',
          redirect: '/404',
        },
      ]
      return asyncRouterMap
    },
    async GenerateMenus({ commit }, { menus, roles }) {
      const processedMenus = generateMenus(menus, roles)
      commit('SET_MENUS', processedMenus)
      return processedMenus
    },
  },
}

export default permission
