import UrlHandler from '@sys/utils/urlHandle'
import router from '@/router'
import store from '@/store'
export const menuNavigate = (menu) => {
  if (menu.meta.target === 'blank') {
    open(menu.meta.url)
  } else {
    router.reloadNext()
    router.push(menu.path)
  }
}
export const generateSsoUrl = (url) => {
  const token = store.state.user.auth.accessToken
  const uh = new UrlHandler(url)

  const targetUrl = uh.build({
    token,
  })
  return targetUrl
}
export const open = (url, { direct = true, token = false } = {}) => {
  let finalUrl = url.replace(/^(\/m\/.*)/, '/b/$1')
  if (token) {
    finalUrl = generateSsoUrl(finalUrl)
  }
  if (!direct) {
    finalUrl = `/redirect?url=${encodeURIComponent(finalUrl)}`
  }
  const a = document.createElement('a')
  a.href = finalUrl
  a.click()
}
export const close = () => {
  if (window.opener && window.history.length === 1) {
    window.close()
  } else {
    router.go(-1)
  }
}
export const isBlankLayout = () => {
  return router.currentRoute.value.path.startsWith('/b/')
}
