import axiosInstance from '@/plugins/axios'
// 需要mock的，需要把地址暴露，并在mock模块引用
export const SYSTEM_API_LOGIN = '/ums/weblogin/loginByPwd'
export const SYSTEM_API_REFRESHTOKEN = '/ums/weblogin/refreshToken'
export const SYSTEM_API_GET_AUTH = '/ums/webhome/webAuths/authCode'

export const login = async ({ username, password }) => {
  const res = await axiosInstance.post(SYSTEM_API_LOGIN, {
    loginname: username,
    password: password,
  })
  return {
    token: res.data.token,
    // refreshToken:''
  }
}

export const doRefreshToken = async ({ refreshToken }) => {
  const res = await axiosInstance.post(SYSTEM_API_REFRESHTOKEN, { refreshToken })
  return {
    token: res.data.token,
    refreshToken: res.data.refreshToken,
  }
}

export const getInfo = async () => {
  const res = await axiosInstance.post(SYSTEM_API_GET_AUTH, { authCode: 'KPI' })
  return {
    permission: {
      menus: res.data,
    },
  }
}
